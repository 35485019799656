import React, { useEffect, useState } from "react";
import {
  dashboardEmailStatisticsChart,
  dashboardEmailStatisticsChart1,
} from "../variables/charts";
import {
  changeMenu,
  getDashboardChartAction,
  getDashboardDataAction,
} from "../Store/Account/Account.action.js";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { axiosClient } from "../../../Constant/apiConstant";
import { updateCurrentPath } from "../../../store/actions/root.actions";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MiniStatisticsCard from "../NewCardsGraphs/Card";
import SoftBox from "../NewCardsGraphs/SoftBox";
import themeRTL from "../NewCardsGraphs/assets/theme/theme-rtl";
import Grid from "@mui/material/Grid";
import ReportsBarChart from "../NewCardsGraphs/ReportsBarChart";
import reportsBarChartData from "../NewCardsGraphs/reportsBarChartData";
import PieChart from "../NewCardsGraphs/PieChart";
import "./dashboard.css";
import {
  LoadingDots,
  isPriceWaived,
  isVodexLoggedin,
} from "../CommonComponents/Common";
import VideoComponentCustom from "../CommonComponents/videomodalCustom";
import OnBoardingForm from "./OnBoardingForm";
import { useHistory } from "react-router-dom";

function Dashboard() {
  const { chart } = reportsBarChartData;
  const dispatch = useDispatch();
  const reducer = useSelector((reducer) => reducer);
  const [state, setState] = useState({
    labels: [],
    values: [],
    spin: false,
    allZero: false,
  });
  const {
    AccountReducer: {
      userDetails: { email, accounts, _id, firstName },
      selectedAccount,
      dashboardData,
      chartData,
      constantData,
    },
  } = reducer;
  let showCurr = selectedAccount?.currency === "inr";
  const { labels, values, allZero } = state;

  useEffect(() => {
    dispatch(updateCurrentPath("Dashboard", ""));
    const init = async () => {};
    init();
  }, []);
  useEffect(() => {
    if (window.location.origin === "https://voice.acquisitionai.com") {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "r4vasznr",
        email: email || "", // Email address
        name: firstName || email || "", // Email address
      };

      // Add the Intercom widget script here
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/r4vasznr";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }

    if (window.location.origin === "https://app.voizer.ai") {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "rvqfqg0r",
        email: email || "",
        name: firstName || email || "",
      };

      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/rvqfqg0r";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }

    dispatch(changeMenu("account"));
    if (!isEmpty(selectedAccount)) {
      axiosClient.defaults.headers.common[
        "dbUrl"
      ] = `${selectedAccount?.accountUrl}`;

      const init = async () => {
        await dispatch(getDashboardDataAction());
        await dispatch(getDashboardChartAction());
      };
      init();
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!isEmpty(chartData)) {
      let valuesArr = [];
      let lablesArr = [];
      let allZero = true;
      for (const key in chartData) {
        if (Object.prototype.hasOwnProperty.call(chartData, key)) {
          const element = chartData[key];
          valuesArr.push(element);
          lablesArr.push(key);
          if (element > 0) {
            allZero = false;
          }
        }
      }

      if (allZero) {
        valuesArr = [1, 1, 1, 1, 1, 1, 1];
      }
      setState((prev) => ({
        ...prev,
        values: valuesArr,
        labels: lablesArr,
        spin: true,
        allZero: allZero,
      }));
    }
  }, [chartData]);

  return (
    <>
      {/* <ProjectUseButton
        onClick={() => history.push("/onboarding?from=dashboard")}
        title="Get Started"
        className="mb-4  use-button-dash"
      /> */}
      {/* <OnBoardingForm /> */}
      {window?.location?.origin === "https://app.voizer.ai" && (
        <VideoComponentCustom />
      )}
      <div>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />

          <SoftBox py={3}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} xl={3}>
                  <MiniStatisticsCard
                    errorMessage={selectedAccount?.balanceExpired}
                    title={{ text: "Account Balance" }}
                    count={
                      isPriceWaived(constantData?.pricesWaivers, email) ? (
                        "--"
                      ) : dashboardData?.balance ? (
                        `${showCurr ? "₹" : "$"} ${dashboardData?.balance}`
                      ) : (
                        <LoadingDots />
                      )
                    }
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "warning", component: "donate" }}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <MiniStatisticsCard
                    title={{ text: "On Hold Balance" }}
                    count={
                      isPriceWaived(constantData?.pricesWaivers, email) ? (
                        "--"
                      ) : dashboardData?.onHoldBalance ? (
                        `${showCurr ? "₹" : "$"} ${
                          dashboardData?.onHoldBalance
                        }`
                      ) : (
                        <LoadingDots />
                      )
                    }
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "warning", component: "donate" }}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <MiniStatisticsCard
                    title={{ text: "Total Call Cost" }}
                    count={
                      isPriceWaived(constantData?.pricesWaivers, email) ? (
                        "--"
                      ) : dashboardData?.totalCost ? (
                        `${showCurr ? "₹" : "$"} ${dashboardData?.totalCost}`
                      ) : (
                        <LoadingDots />
                      )
                    }
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "warning", component: "dollar" }}
                  />{" "}
                </Grid>
                <Grid item xs={12} sm={6} xl={3}>
                  <MiniStatisticsCard
                    title={{ text: "Total Remaining Minutes" }}
                    count={
                      isPriceWaived(constantData?.pricesWaivers, email) ? (
                        "--"
                      ) : dashboardData?.remainingMinutes ? (
                        dashboardData?.remainingMinutes
                      ) : (
                        <LoadingDots />
                      )
                    }
                    percentage={{ color: "success", text: "+55%" }}
                    icon={{ color: "warning", component: "clock" }}
                  />{" "}
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                  <PieChart
                    title="Total Calls Data"
                    description={<>Campaign Performance</>}
                    chart={chart}
                    data1={dashboardEmailStatisticsChart.data(
                      dashboardData?.callStatus?.totalEnded || 1,
                      dashboardData?.callStatus?.totalFailed || 1
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={7}>
                  <ReportsBarChart
                    title="Campaigns Data"
                    description={<>Last 7 days campaign run details</>}
                    chart={chart}
                    data1={dashboardEmailStatisticsChart1.data(labels, values)}
                    allZero={allZero}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </SoftBox>
        </ThemeProvider>

        {isVodexLoggedin() && (
          <div className="floating-widget">
            {/* Widget content */}
            <i
              className="fab fa-discord"
              title="Connect with us on discord"
              onClick={() =>
                window.open(
                  "https://discord.com/channels/1139884697221070848/1139885671713095740",
                  "_blank"
                )
              }
            ></i>{" "}
          </div>
        )}
        {isVodexLoggedin() && (
          <div className="floating-widget-youtube">
            {/* Widget content */}
            <i
              className="fab fa-youtube"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/watch?v=4nSIwHPJlTs&list=PLN4jqjD9BdXxOqx7Ex0S6doiwdooGfwAK",
                  "_blank"
                )
              }
            ></i>{" "}
          </div>
        )}
      </div>
    </>
  );
}

export default Dashboard;
